import React from 'react';
import './BackgroundContainer.css'; // Use a separate CSS file for styling

const BackgroundContainer = ({ children, isLightMode }) => {
  return (
    <div className={isLightMode ? 'background-light' : 'background'}>
      {children}
    </div>
  );
};

export default BackgroundContainer;