import React from 'react';
import { Typography, Container, Box } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import './WorkInProgress.css'; // Import your custom styles

const WorkInProgress = () => {
  return (
    <Container maxWidth="sm" className="work-in-progress">
      <Box>
        <ConstructionIcon style={{ fontSize: 80}} />
        <Typography className="terminal-title" variant="h3" component="h1" gutterBottom>
          Work In Progress
        </Typography>
        <Box className="terminal-box">
          <Typography variant="body1" className="terminal-text">
            My portfolio is currently under construction. Check back soon for the full experience!
          </Typography>
          <Typography variant="body1" className="right-align-text">
            -N8
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default WorkInProgress;