import React, {useState, useEffect} from 'react';
import Header from './components/Header';
import Card from './components/Card'
import BackgroundContainer from './components/BackgroundContainer';
import WorkInProgress from './components/WorkInProgress';


const App = () => {
  const [isLightMode, setIsLightMode] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const toggleMode = () => {
    setIsLightMode(!isLightMode);
  };
  useEffect(() => {
    // Check if the device is mobile based on the userAgent
    const checkMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        setIsMobile(true);
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkMobile(); // Check on initial render

    // Optionally, add an event listener for window resize
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize); // Adjust for window resize

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up event listener
    };
  }, []);
  return (
    <div className="App">
      <BackgroundContainer isLightMode={isLightMode}>   
      <div>
      <Header isLightMode={isLightMode} toggleMode={toggleMode} isMobile={isMobile}/>
      <Card isLightMode={isLightMode}><WorkInProgress/></Card>
      </div>
    </BackgroundContainer>
    </div>
  );
}

export default App;