import React from 'react';
import './Card.css';
import WorkInProgress from './WorkInProgress';

const Card = ({children, isLightMode}) => {

  return (
   
<div className={isLightMode ? 'screen-container-light' : 'screen-container'}>
<div className={isLightMode ? 'screen-backlight-light' : 'screen-backlight'}>
<div className={isLightMode ? 'screen-card-light' : 'screen-card'}>
<div className={isLightMode ? 'scan-line-light' : 'scan-line'}></div>
   <div className="reflection-ellipse"></div>
      <div className="screen-content">
         {children}
      </div>
   </div>
</div>
</div>
  )
};

export default Card;