// src/components/Header.js
import React, {useState} from 'react';
import './Header.css';
import NightLight from './NightLight';

const Header = ({isLightMode, toggleMode, isMobile}) => {
  const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = ({isLightMode}) => {
        setIsOpen(!isOpen); // Toggle menu open/closed
    };

  return (
    <header className={"header" + (isLightMode ? '-light': '')}>
      <div className={"header__logo" + (isLightMode ? '-light': '')}>
        <a  href='https://www.nathan-anderson.com'>nathan<span >-</span>anderson</a>
      </div>

      <NightLight isLightMode={isLightMode} toggleMode={toggleMode} isMobile={isMobile}/>

      <nav className={`header__nav ${isOpen ? 'open' : ''}` + (isLightMode ? '-light': '')}>
        <ul>
          <li><a href="#home"><span >/</span>home</a></li>
          <li><a href="#about"><span >/</span>about</a></li>
          <li><a href="#projects"><span >/</span>projects</a></li>
          <li><a href="#contact"><span >/</span>contact</a></li>
        </ul>
      </nav>
      <div className={"hamburger" + (isLightMode ? '-light': '')} onClick={toggleMenu} >
                <div></div>
                <div></div>
                <div></div>
            </div>
    </header>
  );
};

export default Header;