import React from 'react';
import { FaSun, FaMoon } from 'react-icons/fa'; // Import Sun and Moon icons
import './NightLight.css'; // Use a separate CSS file for styling

const NightLight = ({toggleMode, isLightMode, isMobile}) => {
  return (
    <div className='toggle-mode-button'>
        <button  className={!isLightMode ? 'sun' : 'moon'} onClick={toggleMode}>
        {!isMobile ? isLightMode ? <FaSun/> : <FaMoon/> : isLightMode ? <div>☀</div> : <div>☾ </div>}
      </button>
    </div>
  );
};

export default NightLight;